import axios from 'axios'

// axios.defaults.withCredentials = true

const api = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    // withCredentials: true,
})

api.interceptors.request.use(function (config) {
    const token = window.sessionStorage.getItem('jwt')
    if (token) {
        config.headers.Authorization = `Bearer ${token}`
    }

    return config
})

api.interceptors.response.use(
    (response) => response,
    (error) => {
        if (error.response.status === 401) {
            if (!error.request.responseURL.includes('auth/login')) {
                window.sessionStorage.removeItem('jwt')
                window.location.href = '/auth/login'
            }
        } else {
            return Promise.reject(error)
        }
    }
)

const Login = (credentials) => {
    return api.post(`api/auth/login`, credentials)
}

const Logout = () => {
    return api.post('api/auth/logout')
}

const Me = () => {
    return api.get('api/auth/me')
}

const ChangePassword = (data) => {
    return api.post(`api/auth/me/password`, data)
}

const GetDrawings = () => {
    return api.get('api/drawings')
}

const CreateDrawing = (params) => {
    return api.post(`api/drawings`, params)
}

const UploadDrawingImage = (file, presignedUrl, onUploadProgress) => {
    return axios.put(presignedUrl, file, {
        headers: { 'Content-Type': file.type },
        withCredentials: false,
        onUploadProgress: onUploadProgress,
    })
}

const GetDrawingUrl = (drawingId, fileName) => {
    return api.post(`api/drawings/${drawingId}/url`, { fileName })
}

const UpdateDrawingUrl = (drawingId, url) => {
    return api.put(`api/drawings/${drawingId}/url`, { url })
}

const GetDrawing = (drawingId) => {
    return api.get(`api/drawings/${drawingId}`)
}

const UpdateDrawing = (drawingId, params) => {
    return api.put(`api/drawings/${drawingId}`, params)
}

const GetDrawingButtons = (drawingId) => {
    return api.get(`api/drawings/${drawingId}/buttons`)
}

const CreateDrawingButton = (drawingId, button) => {
    return api.post(`api/drawings/${drawingId}/buttons`, button)
}

const UpdateDrawingButton = (drawingId, buttonId, data) => {
    return api.put(`api/drawings/${drawingId}/buttons/${buttonId}`, data)
}

const DeleteDrawingButton = (drawingId, buttonId) => {
    return api.delete(`api/drawings/${drawingId}/buttons/${buttonId}`)
}

const GetDrawingSubDrawings = (drawingId) => {
    return api.get(`api/drawings/${drawingId}/subdrawings`)
}

const GetDrawingSubDrawing = (drawingId, subDrawingId) => {
    return api.get(`api/drawings/${drawingId}/subdrawings/${subDrawingId}`)
}

const GetDrawingParts = (drawingId, filter) => {
    return api.get(`api/drawings/${drawingId}/parts`, { params: { filter: filter } })
}

const SearchDrawingParts = (drawingId, buttonId, keywords) => {
    return api.get(`api/drawings/${drawingId}/buttons/${buttonId}/parts/search?keywords=${encodeURIComponent(keywords)}`)
}

const ImportDrawingParts = (drawingId, formData, onUploadProgress) => {
    return api({
        method: 'post',
        url: `api/drawings/${drawingId}/parts/import`,
        data: formData,
        headers: { 'Content-Type': 'multipart/form-data' },
        onUploadProgress: onUploadProgress,
    })
}

const GetButtonPart = (drawingId, buttonId, partId) => {
    return api.get(`api/drawings/${drawingId}/buttons/${buttonId}/parts/${partId}`)
}

const GetButtonSubDrawing = (drawingId, buttonId, subDrawingId) => {
    return api.get(`api/drawings/${drawingId}/subdrawings/${subDrawingId}`)
}

const GetServiceOrders = (status = 0, filter = '') => {
    return api.get('api/service-orders', { params: { status: status, filter: filter } })
}

const GetServiceOrder = (serviceOrderId) => {
    return api.get(`api/service-orders/${serviceOrderId}`)
}

const ApproveServiceOrder = (serviceOrderId) => {
    return api.post(`api/service-orders/${serviceOrderId}/approve`)
}

const GetServiceOrderReprovalReasons = () => {
    return api.get('api/service-orders/reproval/reasons')
}

const ReproveServiceOrder = (serviceOrderId, reasonId) => {
    return api.post(`api/service-orders/${serviceOrderId}/reprove`, { reason: reasonId })
}

const GetServiceOrderStatuses = (status) => {
    return api.get('api/service-orders/statuses')
}

const CheckExportServiceOrder = (serviceOrderId) => {
    return api.get(`api/service-orders/${serviceOrderId}/export/check`)
}

const ExportServiceOrder = (serviceOrderId) => {
    return api.get(`api/service-orders/${serviceOrderId}/export`)
}

// Admin

const AdminGetUsers = () => {
    return api.get('api/admin/users')
}

const AdminCreateUser = (data) => {
    return api.put(`api/admin/users`, data)
}

const AdminSaveUser = (userId, data) => {
    return api.post(`api/admin/users/${userId}`, data)
}

const AdminResetUserPassword = (userId, password) => {
    return api.post(`api/admin/users/${userId}/reset-password`, { password })
}

export {
    api,
    Login,
    Logout,
    Me,
    ChangePassword,
    GetDrawings,
    CreateDrawing,
    UploadDrawingImage,
    GetDrawingUrl,
    UpdateDrawingUrl,
    GetDrawing,
    UpdateDrawing,
    GetDrawingButtons,
    CreateDrawingButton,
    UpdateDrawingButton,
    DeleteDrawingButton,
    GetDrawingSubDrawings,
    GetDrawingSubDrawing,
    GetDrawingParts,
    SearchDrawingParts,
    ImportDrawingParts,
    GetButtonPart,
    GetButtonSubDrawing,
    GetServiceOrders,
    GetServiceOrderStatuses,
    GetServiceOrder,
    ApproveServiceOrder,
    GetServiceOrderReprovalReasons,
    ReproveServiceOrder,
    ExportServiceOrder,
    CheckExportServiceOrder,
    AdminGetUsers,
    AdminCreateUser,
    AdminSaveUser,
    AdminResetUserPassword,
}
