import React, { useEffect, useState } from 'react'
import DrawingsGrid from '../../components/domain/Drawings/DrawingsGrid'
import PageBody from '../../components/ui/Pages/PageBody'
import PageTitle from '../../components/ui/Pages/PageTitle'
import { GetDrawings } from '../../services/Api'
import { useApp } from '../../contexts/AppContext'
import CreateDrawing from '../../components/domain/Drawings/CreateDrawing'
import PrimaryButton from '../../components/ui/Buttons/PrimaryButton'

export default function Drawings() {
    const [drawings, setDrawings] = useState([])
    const [showCreateDrawing, setShowCreateDrawing] = useState(false)

    const { setLoading } = useApp()

    useEffect(() => {
        loadDrawings()

        function loadDrawings() {
            setLoading(true)
            GetDrawings()
                .then((response) => {
                    setDrawings(response.data)
                })
                .catch((error) => {
                    console.log(error)
                })
                .finally(() => setLoading(false))
        }
    }, [setLoading])

    return (
        <>
            <PageTitle title="Desenhos">
                <div className="flex items-center gap-4">
                    <PrimaryButton onClick={() => setShowCreateDrawing(true)}>Novo desenho</PrimaryButton>
                </div>
            </PageTitle>
            <PageBody>
                <DrawingsGrid drawings={drawings} />
            </PageBody>
            {showCreateDrawing && <CreateDrawing onClose={() => setShowCreateDrawing(false)} />}
        </>
    )
}
