import React, { useEffect, useState } from 'react'
import PageBody from '../../components/ui/Pages/PageBody'
import PageTitle from '../../components/ui/Pages/PageTitle'
import { GetServiceOrders, GetServiceOrderStatuses } from '../../services/Api'
import { useApp } from '../../contexts/AppContext'
import { useNavigate } from 'react-router-dom'
import { format, parseISO } from 'date-fns'
import FigureButton from '../../components/ui/Buttons/FigureButton'
import { useRef } from 'react'

export default function ServiceOrders() {
    const [statuses, setStatuses] = useState([])
    const [orders, setOrders] = useState([])
    const [selectedStatus, setSelectedStatus] = useState(null)

    const filterRef = useRef()

    const { setLoading } = useApp()
    const navigate = useNavigate()

    useEffect(() => {
        GetServiceOrderStatuses()
            .then((response) => {
                const responseStatuses = response.data
                setStatuses(responseStatuses)

                if (responseStatuses.length > 0) {
                    const firstStatus = responseStatuses[0]
                    setSelectedStatus(firstStatus.id)
                }
            })
            .catch((error) => {
                console.log(error)
            })
            .finally(() => setLoading(false))
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        if (!isNaN(selectedStatus)) {
            loadOrders()
        }
        // eslint-disable-next-line
    }, [selectedStatus])

    function loadOrders() {
        setLoading(true)
        const filterText = filterRef.current.value
        GetServiceOrders(selectedStatus, filterText)
            .then((response) => {
                if (Array.isArray(response.data)) {
                    setOrders(response.data)
                } else {
                    let filteredOrder = response.data
                    if (filteredOrder && filteredOrder.id) {
                        navigate(`/service-orders/${filteredOrder.id}`)
                    }
                }
            })
            .catch((error) => {
                console.log(error)
            })
            .finally(() => setLoading(false))
    }

    function handleFilterTextChanged(event) {
        event.preventDefault()
        filterRef.current.focus()
        loadOrders()
    }

    function clearFilter() {
        filterRef.current.value = ''
        filterRef.current.focus()
        loadOrders()
    }

    return (
        <>
            <PageTitle title="Solicitações de Componente">
                <div className="flex items-center gap-4"></div>
            </PageTitle>
            <PageBody>
                <div className="flex space-x-3 mb-8 items-stretch">
                    {statuses.map((status) => {
                        return (
                            <FigureButton key={status.id} value={status.count} onClick={() => setSelectedStatus(status.id)} active={selectedStatus === status.id}>
                                {status.name}
                            </FigureButton>
                        )
                    })}
                    <div className="w-full bg-gray-100 px-5 rounded-lg flex items-center">
                        <form onSubmit={handleFilterTextChanged} className="w-full flex items-center space-x-4">
                            <input type="text" ref={filterRef} className="w-full text-gray-600 bg-transparent flex-grow" placeholder="Filtro" />
                            {filterRef.current && filterRef.current.value !== '' && (
                                <button type="button" className="text-gray-600 active:scale-95" onClick={clearFilter}>
                                    <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12"></path>
                                    </svg>
                                </button>
                            )}
                            <button type="submit" className="text-gray-600 active:scale-95">
                                <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"></path>
                                </svg>
                            </button>
                        </form>
                    </div>
                </div>

                {orders.length > 0 ? (
                    <table className="w-full text-sm text-left text-gray-500">
                        <thead className="text-xs text-gray-700 uppercase bg-silver-light">
                            <tr>
                                <th scope="col" className="px-6 py-3">
                                    Data
                                </th>
                                <th scope="col" className="px-6 py-3">
                                    Usuário
                                </th>
                                <th scope="col" className="px-6 py-3">
                                    OS BASF
                                </th>
                                <th scope="col" className="px-6 py-3">
                                    Requer Usinagem
                                </th>
                                <th scope="col" className="px-6 py-3">
                                    Tag
                                </th>
                                <th scope="col" className="px-6 py-3">
                                    Itens
                                </th>
                                <th scope="col" className="px-6 py-3">
                                    Status
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {orders.map((order) => {
                                return (
                                    <tr
                                        className="border-b odd:bg-white even:bg-gray-50 cursor-pointer hover:bg-gray-100"
                                        key={order.id}
                                        onClick={() => navigate(`/service-orders/${order.id}`)}
                                    >
                                        <td className="px-6 py-4">{format(parseISO(order.date), 'dd/MM/yyyy HH:mm')}</td>
                                        <td className="px-6 py-4">{order.user_name}</td>
                                        <td className="px-6 py-4">{order.os_basf}</td>
                                        <td className="px-6 py-4">{order.requires_machining ? 'Sim' : 'Não'}</td>
                                        <td className="px-6 py-4">{order.tag}</td>
                                        <td className="px-6 py-4">{order.items_count}</td>
                                        <td className="px-6 py-4">{order.status.name}</td>
                                    </tr>
                                )
                            })}
                        </tbody>
                    </table>
                ) : (
                    <div className="p-4 text-gray-700 bg-gray-100 rounded-lg w-full text-sm">Sem solicitações de componente</div>
                )}
            </PageBody>
        </>
    )
}
