import React, { useState, useEffect, useRef } from 'react'
import { useOutletContext } from 'react-router-dom'
import { toast } from 'react-toastify'
import { useApp } from '../../../contexts/AppContext'
import { GetDrawingParts, ImportDrawingParts } from '../../../services/Api'
import Button from '../../ui/Buttons/Button'
import CircularProgressModal from '../../ui/Modals/CircularProgressModal'

export default function DrawingParts() {
    const { drawing } = useOutletContext()
    const [parts, setParts] = useState([])
    const [selectedTab, setSelectedTab] = useState('assigned')
    const [uploading, setUploading] = useState(false)
    const [uploadProgress, setUploadProgress] = useState(0)

    const { setLoading } = useApp()

    const inputFileRef = useRef()

    useEffect(() => {
        loadParts(selectedTab)
        // eslint-disable-next-line
    }, [selectedTab])

    const TabMenu = ({ children, to, selected }) => {
        const getStyleClasses = () => {
            const defaultClasses = ['inline-block', 'p-4', 'rounded-t-lg']
            if (selectedTab === to) {
                return defaultClasses.concat(['text-gray-700', 'bg-silver-light']).join(' ')
            } else {
                return defaultClasses.concat(['hover:text-gray-600', 'hover:bg-gray-50']).join(' ')
            }
        }

        return (
            <button className={getStyleClasses()} aria-current="page" onClick={() => setSelectedTab(to)}>
                {children}
            </button>
        )
    }

    function loadParts(tab) {
        setLoading(true)
        GetDrawingParts(drawing.id, tab)
            .then((response) => {
                setParts(response.data)
            })
            .finally(() => setLoading(false))
    }

    function handleImportClick() {
        inputFileRef.current.click()
    }

    function onFileChange(ev) {
        if (ev.target.files.length > 0) {
            const file = ev.target.files[0]
            console.log(file)

            var formData = new FormData()
            formData.append('file', file)

            setUploadProgress(0)
            setUploading(true)

            ImportDrawingParts(drawing.id, formData, handleUploadProgress)
                .then((response) => {
                    loadParts('all')

                    const totalImportedParts = response.data
                    if (totalImportedParts > 0) {
                        toast.success(response.data + ' peças importadas com sucesso!')
                    } else {
                        toast.warn('Nenhuma peça importada!')
                    }
                })
                .catch((error) => {
                    console.log(error)
                    toast.error('Ocorreu um error ao importar as peças!')
                })
                .finally(() => setUploading(false))
        }
    }

    const handleUploadProgress = (progressEvent) => {
        let progress = Math.round((progressEvent.loaded * 100) / progressEvent.total)
        setUploadProgress(progress)
        console.log(progress)
    }

    return (
        <div>
            <ul className="flex items-start border-b border-gray-200">
                <div className="flex flex-wrap text-sm font-medium text-center text-gray-500 flex-grow">
                    <li className="mr-2">
                        <TabMenu to="assigned">Peças mapeadas</TabMenu>
                    </li>
                    <li className="mr-2">
                        <TabMenu to="unassigned">Não mapeadas</TabMenu>
                    </li>
                    <li className="mr-2">
                        <TabMenu to="all">Todas</TabMenu>
                    </li>
                </div>

                {selectedTab === 'all' && (
                    <Button onClick={handleImportClick} disabled={parts.length > 0}>
                        <input className="visually-hidden" type="file" name="document" accept="text/csv" ref={inputFileRef} onChange={onFileChange} />

                        <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                                d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"
                            ></path>
                        </svg>
                        <span>Importar</span>
                    </Button>
                )}
            </ul>

            {uploading && <CircularProgressModal text="Enviando arquivo..." progress={uploadProgress} />}

            <table className="w-full text-sm text-left text-gray-500">
                <thead className="text-xs text-gray-700 uppercase bg-silver-light">
                    <tr>
                        <th scope="col" className="px-6 py-3">
                            Posição
                        </th>
                        <th scope="col" className="px-6 py-3">
                            Nome
                        </th>
                        <th scope="col" className="px-6 py-3">
                            Identificação
                        </th>
                        <th scope="col" className="px-6 py-3">
                            Código BASF
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {parts.length > 0 ? (
                        parts.map((part) => {
                            return (
                                <tr className="border-b odd:bg-white even:bg-gray-50 text-xs" key={part.id}>
                                    <td className="px-6 py-2">{part.drawing_location}</td>
                                    {/* <th scope="row" className="px-6 py-4 font-medium text-gray-900  whitespace-nowrap">
                                        <Link to={`/drawings/${drawing.id}/parts/${part.id}`} className="hover:text-brand">
                                            {part.name}
                                        </Link>
                                    </th> */}
                                    <td className="px-6 py-2">{part.name}</td>
                                    <td className="px-6 py-2">{part.identification}</td>
                                    <td className="px-6 py-2">{part.basf_code}</td>
                                </tr>
                            )
                        })
                    ) : (
                        <tr>
                            <td colSpan={4} className="py-6">
                                <div className="p-4 text-gray-700 bg-gray-100 rounded-lg w-full text-sm">Nenhuma peça retornada</div>
                            </td>
                        </tr>
                    )}
                </tbody>
            </table>
        </div>
    )
}
