import React from 'react'
import Page from '../components/ui/Pages/Page'
import MainMenu from '../components/ui/MainMenu'
import { Outlet, useLocation } from 'react-router-dom'
import { useState } from 'react'
import { useEffect } from 'react'

export default function Home() {
    const [menu, setMenu] = useState('service-orders')
    const location = useLocation()

    useEffect(() => {
        if (location.pathname && location.pathname.length > 0 && location.pathname.substring(0, 1) === '/') {
            const urlSegments = location.pathname.substring(1).split('/')
            if (urlSegments.length > 0 && urlSegments[0] !== '') {
                setMenu(urlSegments[0])
            }
        }
    }, [location])

    return (
        <Page>
            <MainMenu selected={menu} />
            <Outlet />
        </Page>
    )
}
