import React, { useEffect, useState } from 'react'
import { UpdateDrawing, GetDrawingUrl, UploadDrawingImage } from '../../../services/Api'
import { getImageDimensions } from '../../../services/Utils'
import FileInput from '../../ui/Forms/FileInput'
import Input from '../../ui/Forms/Input'
import CircularProgressModal from '../../ui/Modals/CircularProgressModal'
import Modal from '../../ui/Modals/Modal'
import { toast } from 'react-toastify'

export default function EditDrawing({ drawing, onClose, onDrawingUpdated }) {
    const [submitting, setSubmitting] = useState(false)
    const [uploading, setUploading] = useState(false)
    const [uploadProgress, setUploadProgress] = useState(0)

    const [name, setName] = useState('')
    const [uploadFile, setUploadFile] = useState()
    const [uploadFileChanged, setUploadFileChanged] = useState(false)

    useEffect(() => {
        setName(drawing.name)

        let file = new File([''], drawing.file_name, { type: 'image/jpg' })
        setUploadFile(file)
    }, [drawing])

    const handleSubmit = () => {
        setSubmitting(true)

        if (uploadFileChanged) {
            getImageDimensions(uploadFile).then((dimensions) => {
                let params = {
                    name: name,
                    fileName: uploadFile.name.replace(/\s/g, ''),
                    width: dimensions.width,
                    height: dimensions.height,
                }

                GetDrawingUrl(drawing.id, params.fileName)
                    .then((response) => {
                        let drawingData = response.data

                        setUploading(true)

                        UploadDrawingImage(uploadFile, drawingData.presignedUrl, handleUploadProgress)
                            .then(() => {
                                setUploading(false)

                                params.url = drawingData.url
                                updateDrawing(params)
                            })
                            .catch((error) => console.log(error))
                            .finally(() => setUploading(false))
                    })
                    .catch((error) => {
                        setSubmitting(false)
                        console.log(error)
                    })

                // setUploading(true)
            })
        } else {
            updateDrawing({ name: name })
        }
    }

    const updateDrawing = (params) => {
        UpdateDrawing(drawing.id, params)
            .then((response) => {
                toast.success('Desenho atualizado com sucesso!')
                onDrawingUpdated(response.data)
                onClose()
            })
            .catch((error) => {
                console.log(error)
            })
            .finally(() => setSubmitting(false))
    }

    const handleUploadProgress = (progressEvent) => {
        let progress = Math.round((progressEvent.loaded * 100) / progressEvent.total)
        setUploadProgress(progress)
    }

    const handleCancel = () => {
        onClose()
    }

    const canSubmit = () => {
        return name.length > 0 && uploadFile != null && !submitting
    }

    return (
        <>
            <Modal
                title="Editar desenho"
                onCancel={handleCancel}
                onSubmit={handleSubmit}
                submitDisabled={!canSubmit()}
                cancelDisabled={submitting}
                submitText="Salvar"
                submitLoading={submitting}
                submitLoadingText="Salvando..."
            >
                <Input label="Nome" placeholder="Nome do desenho" disabled={submitting} value={name} setValue={setName} autoFocus />
                <FileInput file={uploadFile} setFile={setUploadFile} accept="image/jpeg" disabled={submitting} onFileChanged={() => setUploadFileChanged(true)} />
            </Modal>
            {uploading && <CircularProgressModal text="Enviando arquivo..." progress={uploadProgress} />}
        </>
    )
}
