import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { CreateDrawing as PostCreateDrawing, UpdateDrawingUrl, UploadDrawingImage } from '../../../services/Api'
import { getImageDimensions } from '../../../services/Utils'
import FileInput from '../../ui/Forms/FileInput'
import Input from '../../ui/Forms/Input'
import CircularProgressModal from '../../ui/Modals/CircularProgressModal'
import Modal from '../../ui/Modals/Modal'

export default function CreateDrawing({ buttonId, onClose, onSaved }) {
    const [submitting, setSubmitting] = useState(false)
    const [uploading, setUploading] = useState(false)
    const [uploadProgress, setUploadProgress] = useState(0)

    const [name, setName] = useState('')
    const [uploadFile, setUploadFile] = useState()

    const navigate = useNavigate()

    const isSubDrawing = () => {
        return buttonId != null
    }

    const handleSubmit = () => {
        setSubmitting(true)

        getImageDimensions(uploadFile).then((dimensions) => {
            let params = {
                name: name,
                fileName: uploadFile.name.replace(/\s/g, ''),
                width: dimensions.width,
                height: dimensions.height,
                is_subdrawing: isSubDrawing(),
            }

            PostCreateDrawing(params)
                .then((response) => {
                    let drawingData = response.data

                    setUploading(true)

                    UploadDrawingImage(uploadFile, drawingData.presignedUrl, handleUploadProgress)
                        .then(() => {
                            setUploading(false)

                            UpdateDrawingUrl(drawingData.id, drawingData.url)
                                .then(() => {
                                    if (isSubDrawing()) {
                                        onSaved(drawingData.id)
                                    } else {
                                        navigate(`/drawings/${drawingData.id}`)
                                    }
                                })
                                .catch((error) => console.log(error))
                                .finally(() => {
                                    setSubmitting(false)
                                })
                        })
                        .catch((error) => console.log(error))
                        .finally(() => setUploading(false))
                })
                .catch((error) => {
                    setSubmitting(false)
                    console.log(error)
                })

            // setUploading(true)
        })
    }

    const handleUploadProgress = (progressEvent) => {
        let progress = Math.round((progressEvent.loaded * 100) / progressEvent.total)
        setUploadProgress(progress)
        console.log(progress)
    }

    const handleCancel = () => {
        onClose()
    }

    const canSubmit = () => {
        return name.length > 0 && uploadFile != null && !submitting
    }

    return (
        <>
            <Modal
                title={buttonId ? 'Novo sub-desenho' : 'Novo desenho'}
                onCancel={handleCancel}
                onSubmit={handleSubmit}
                submitDisabled={!canSubmit()}
                cancelDisabled={submitting}
                submitText="Salvar"
                submitLoading={submitting}
                submitLoadingText="Salvando..."
            >
                <Input label="Nome" placeholder="Nome do desenho" disabled={submitting} value={name} setValue={setName} autoFocus />
                <FileInput file={uploadFile} setFile={setUploadFile} accept="image/jpeg" disabled={submitting} />
            </Modal>
            {uploading && <CircularProgressModal text="Enviando arquivo..." progress={uploadProgress} />}
        </>
    )
}
