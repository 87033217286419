import React from 'react'
import { Link } from 'react-router-dom'
import { format, parseISO } from 'date-fns'

export default function DrawingsGrid({ drawings }) {
    return drawings.length > 0 ? (
        // <div className="flex flex-col space-y-4">
        //     {drawings.map((drawing) => {
        //         return (
        //             <Link to={`/drawings/${drawing.id}`} key={drawing.id}>
        //                 <div className="rounded border p-4 text-gray-600">{drawing.name}</div>
        //             </Link>
        //         )
        //     })}
        // </div>
        // <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
        <table className="w-full text-sm text-left text-gray-500">
            <thead className="text-xs text-gray-700 uppercase bg-silver-light">
                <tr>
                    <th scope="col" className="px-6 py-3">
                        Nome
                    </th>
                    <th scope="col" className="px-6 py-3">
                        Peças
                    </th>
                    <th scope="col" className="px-6 py-3">
                        Sub-desenhos
                    </th>
                    <th scope="col" className="px-6 py-3">
                        Criado em
                    </th>
                </tr>
            </thead>
            <tbody>
                {drawings.map((drawing) => {
                    return (
                        <tr className="border-b odd:bg-white even:bg-gray-50 " key={drawing.id}>
                            <th scope="row" className="px-6 py-4 font-medium text-gray-900  whitespace-nowrap">
                                <Link to={`/drawings/${drawing.id}`} className="hover:text-brand">
                                    {drawing.name}
                                </Link>
                            </th>
                            <td className="px-6 py-4">{drawing.partsCount}</td>
                            <td className="px-6 py-4">{drawing.subdrawingCount}</td>
                            <td className="px-6 py-4">{format(parseISO(drawing.created_at), 'dd/MM/yyyy HH:mm')}</td>
                        </tr>
                    )
                })}
            </tbody>
        </table>
    ) : (
        <div className="p-4 text-gray-700 bg-gray-100 rounded-lg w-full text-sm">Sem desenhos</div>
    )
}
